.text-io {
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
    transition: 2s ease;
  }
  .text-io.hide-item {
    --length: 6px;
    opacity: 0;
    margin-left: calc(var(--length)*-1);
    margin-right: var(--length);
  }
  .text-io:nth-of-type(2) {
    transition-delay: .8s;
  }
  .text-io:nth-of-type(3) {
    transition-delay: 1.6s;
  }
  .text-io:nth-of-type(4) {
    transition-delay: 2.4s;
  }
  .text-io:nth-of-type(5) {
    transition-delay: 3.2s;
  }
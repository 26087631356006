/* Home Hero Style */

.hero-info {
    position: absolute;
    height: 100%;
    width: 50%;
    max-height: calc(768px - 83px);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}


.hero-section {
    /* height: calc(100dvh - 83px); */
    max-height: calc(768px - 83px);
    overflow: hidden;
}
.hero-section > div,
.hero-section > div > section ,
.hero-section > div > section > div  { 
    height: 100%;
    position: relative;
}

.hero-section img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.5);
}
.hero-section h1 {
    font-size: 5vw;
}
.hero-section h4 {
    font-size: 2.5vw;
    font-weight: 400;
}
.hero-section .text-io {
    font-size: 2vw;
    font-weight: 300;
}

.home-page > section > div + div {
    margin-top: 40px;
}

.home-page .hero-info {
    left: 0!important;
}
.services-page .hero-info {
    right: 0!important;
}
.hero-info .spacer {
    height: 10vw;
    max-height: 80px;
}
.hero-info > div {
    color: #FFF;
    display: flex;
    flex-direction: column;
    padding-top: 120px;
}
@media (max-width: 739px){
    .hero-info {
        width: 67%;
    }
    .hero-section {
        height: calc(100dvh - 83px);
    }
    .hero-section h1 {
        font-size: 10vw;
    }
    .hero-section h4 {
        font-size: 5vw;
        font-weight: 400;
    }
    .hero-section .text-io {
        font-size: 4.5vw;
        font-weight: 300;
    }

    .home-page .hero-info {
        right: 20px;
    }
    
    .services-page .hero-info {
        left: 20px;
    }

    .home-page .hero-section img {
        object-position: 63%
    }
    
    .services-page .hero-section img {
        object-position: 37%
    }

}
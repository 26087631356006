.hamburger-container {
    /* position: absolute; */
    z-index: 10;
    /* right: 0; */
    /* top: 11px; */
    cursor: pointer;
}

.hamburger-container .hamburger-close > section {
  margin: 9px 0!important;
}
.hamburger-container .hamburger-close > section:first-of-type {
  transform: rotate(45deg)!important;
}
.hamburger-container .hamburger-close > section:last-of-type {
  transform: rotate(-45deg)!important;
  margin-top: -12px!important;
}  

#hamburger-btn > section {
  margin: 6px 0;
  width: 30px;
  height: 3px;
  background-color: #ffffff;
  transition: all .3s ease;
}
#hamburger-btn.hamburger-close > section {
  background-color: var(--color-hex-theme-light);
}

  


.draw-display #hamburger-btn.hamburger-close > section  {
  width: 20px;
  margin: 0px 0!important;
}

/* .draw-display #hamburger-btn.hamburger-close {
  padding-top: 10px;
  margin-top: -30px;
} */

.draw-display #hamburger-btn.hamburger-close > section:first-of-type {
  transform: rotate(45deg)!important;
  margin-top: -3px!important;
}
.draw-display #hamburger-btn.hamburger-close > section:last-of-type {
  transform: rotate(-45deg)!important;
  margin-top: 9px!important;
}  